import { lazy } from 'react';
import DashboardLayout from 'Layout/Dashboard';
import ProfileLayout from 'Layout/Profile';

const routes = [
  {
    path: '/dashboard',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard/changelog',
        exact: true,
        component: lazy(() => import('components/Changelog')),
      },
      {
        component: lazy(() => import('components/Dashboard/Default')),
      },
    ],
  },
  {
    path: '/quotation',
    component: DashboardLayout,
    routes: [
      {
        component: lazy(() => import('components/Quotations')),
      },
    ],
  },
  {
    path: '/wallet',
    component: DashboardLayout,
    routes: [
      {
        component: lazy(() => import('components/Wallet')),
      },
    ],
  },
  {
    path: '/profile',
    component: ProfileLayout,
    routes: [
      {
        component: lazy(() => import('components/Profile')),
      },
    ],
  },
];

export default routes;
